import { Navigate, Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { setCookie } from 'helper'
import { useCurrentUser } from '@/api/index'
import { PageLoader } from '@/components/index'
import { RegisterStateEnum } from '@/types/user'

type TAuthProps = {
  shouldRedirect?: boolean
}

function Auth({ shouldRedirect }: TAuthProps) {
  const { isLoading, isError, data } = useCurrentUser()
  useEffect(() => {
    const url = window.location.href
    const cookie = url.split('?')[1]

    if (cookie) {
      document.cookie = ''
      const session = decodeURIComponent(cookie.split('&')[1]).split('=')[1]
      setCookie('session', session, 7)
    }
  }, [])

  if (isLoading) {
    return <PageLoader />
  }

  if (isError || !data) {
    return <Navigate to={'/'} />
  }
  if (
    data.submissionProgress.stepSubmission !== RegisterStateEnum.DOCUMENTS &&
    shouldRedirect
  ) {
    return (
      <Navigate
        to={`/registro?stepSubmission=${data.submissionProgress.stepSubmission}`}
      />
    )
  }
  return <Outlet />
}

export { Auth }
