import { ArrowDownTrayIcon, DocumentIcon } from '@heroicons/react/24/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  IconButton,
  Input,
  Tooltip,
  Typography
} from '@material-tailwind/react'
import { useUploadActsTableState } from './hooks'
import { Department } from './components'
import { useSurveys } from '@/api/surveys'

const TABLE_HEAD = [
  'Código de acta',
  'Fecha de subida',
  'Municipio',
  'Departamento',
  ''
]

export function UplaodedActsTable() {
  const { currentPage, handleBefore, handleNext, setSelectedSurvey, total } =
    useUploadActsTableState()

  const { data = [] } = useSurveys({
    params: {
      skip: (currentPage - 1) * 30,
      limit: 30
    }
  })
  return (
    <Card
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
      placeholder={''}
      className="h-full w-full"
    >
      <CardHeader
        placeholder={''}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        floated={false}
        shadow={false}
        className="rounded-none"
      >
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography placeholder={''} variant="h5" color="blue-gray">
              Actas Subidas
            </Typography>
            <Typography
              placeholder={''}
              color="gray"
              className="mt-1 font-normal"
            >
              Estas son todas las actas que fuerón subidas
            </Typography>
            <Chip value={`${total} Actas subidas`} className="my-2 !w-fit" />
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                crossOrigin={''}
                placeholder={''}
                label="Buscar"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder={''}
        className="overflow-auto px-0"
      >
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map(head => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    placeholder={''}
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((act, index) => {
              const isLast = index === data.length - 1
              const classes = isLast
                ? 'p-4'
                : 'p-4 border-b border-blue-gray-50'

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <DocumentIcon className="h-6 w-6" />
                      <Typography
                        placeholder={''}
                        variant="small"
                        color="blue-gray"
                        className="font-bold"
                      >
                        {act.surveyCode}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography
                      placeholder={''}
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      <p>{new Date(act.createdAt).toLocaleDateString()}</p>
                      <p>{new Date(act.createdAt).toLocaleTimeString()}</p>
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      placeholder={''}
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {act.municipality?.name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Department municipalityId={act.municipalityId} />
                  </td>
                  <td className={classes}>
                    <Tooltip content="Descargar Acta">
                      <IconButton
                        placeholder={''}
                        variant="text"
                        onClick={() => setSelectedSurvey(act)}
                      >
                        <ArrowDownTrayIcon className="h-6 w-6" />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder={''}
        className="flex items-center justify-between border-t border-blue-gray-50 p-4"
      >
        <Button
          onClick={handleBefore}
          disabled
          placeholder={''}
          variant="outlined"
          size="sm"
        >
          Anterior
        </Button>
        <div className="flex items-center gap-2">
          <IconButton placeholder={''} variant="outlined" size="sm">
            {currentPage}
          </IconButton>
        </div>
        <Button
          onClick={handleNext}
          disabled
          placeholder={''}
          variant="outlined"
          size="sm"
        >
          Siguiente
        </Button>
      </CardFooter>
    </Card>
  )
}
