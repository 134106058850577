import { Link } from 'react-router-dom'
import { DownloadButton, Footer } from '@/components/index'

function TermsAndConditionsPage() {
  return (
    <>
      <div className="relative flex h-[calc(100dvh-58px)] w-full items-center justify-center overflow-y-auto ">
        <div className="m-auto max-w-7xl px-3 py-5 md:px-0">
          <h1 className="mb-4 text-center text-3xl font-bold" id="avisolegal">
            AVISO LEGAL
          </h1>
          <p>
            En el presente aviso se regulan los derechos y obligaciones de la
            plataforma web <strong>CONTROLCENSO.COM</strong> y de los usuarios
            en relación al acceso, navegación y utilización de la plataforma web{' '}
            <strong>CONTROLCENSO.COM</strong> sin perjuicio de que la plataforma
            web <strong>CONTROLCENSO.COM</strong> se reserva el derecho a
            modificar, sin previo aviso su contenido, siendo vigente el que se
            encuentre publicado en el momento de navegación. Concordante con la
            Ley Nº 341 de Participación y <strong>Control Social</strong> y su
            reglamentación.Específicamente en cumplimiento a lo establecido en
            el artículo 3 en el que se describen los fines de la participación y
            control social, así como los principios descritos en el artículo 4
            de la ley precitada.
          </p>
          <p>
            Ejerciendo dicha atribución constitucional, desde los tres tipos de
            actores que describe la Ley.
          </p>
          <p>
            Al momento de suscribirse en la plataforma web; en el ejercicio
            pleno de la democracia representativa; acepta sumarse a la solicitud
            colectiva a la Comisión de Organización Territorial del Estado y
            Autonomías de la Cámara de Diputados de la Asamblea Legislativa
            Plurinacional de Bolivia y/o a los Comités Cívicos departamentales
            para que en el marco de sus atribuciones normativas, realice la
            fiscalización/control social correspondiente al proceso censal,
            salvaguardando todos los derechos y garantías constitucionales.
          </p>
          <p>
            Téngase presente que, conforme al artículo 5 de la Ley Nº 1405 de
            Estadísticas Oficiales del Estado Plurinacional de Bolivia, la
            plataforma web no revela en ningún momento y bajo ninguna
            circunstancia ningún acta censal que puede tener algún dato
            personal, identificado y/o identificable. Ninguno de los
            desarrolladores de la plataforma web en calidad de contratados, ni
            los contratantes tienen los permisos necesarios para acceder a las
            fotografías de boletas censales subidas voluntariamente de los
            ciudadanos.
          </p>
          <p>
            Únicamente, se podrá realizar la auditoría informática
            correspondiente adscritos al principio de trazabilidad del hash
            criptográfico correspondiente designado a cada contenido multimedia
            que cada usuario, de manera voluntaria suba a la plataforma web.
          </p>
          <p>
            Mediante la navegación y uso de la plataforma web{' '}
            <strong>CONTROLCENSO.COM</strong>
            adquieres la condición de usuario y por tanto implica la aceptación
            expresa de todas las cláusulas que se indican en el presente Aviso
            Legal, así como el resto de advertencias o cláusulas específicas que
            se establezcan en las diferentes secciones del uso de servicios.
          </p>
          <p>
            En caso de no aceptar las cláusulas establecidas en el presente
            Aviso Legal, deberá abstenerse de acceder y/o utilizar los servicios
            puestos a disposición en la plataforma web.
          </p>
          <h2 className="mt-4 text-xl font-bold">
            1. Propiedad de la Plataforma:
          </h2>
          <ul className="mb-4 list-disc pl-4">
            <li>
              La Plataforma es propiedad de “ALHASOFT, MARIELA BALDIVIESO
              CASTILLO y LUCIANA MICHELLE CAMPERO CHAVEZ”, y ha sido
              desarrollada bajo la iniciativa ciudadana de{' '}
              <strong>control social</strong> al CENSO BOLIVIA 2024.
            </li>
            <li>
              Los costos asociados con el desarrollo y mantenimiento de la
              Plataforma han sido subvencionados por ciudadanos de Bolivia de
              forma voluntaria mediante donaciones bajo la premisa del{' '}
              <strong>control social</strong> al CENSO BOLIVIA 2024.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">2. Uso de la Plataforma:</h2>
          <p>
            Usted acepta utilizar la Plataforma web únicamente con fines legales
            y de acuerdo con este Aviso Legal y todas las leyes y regulaciones
            aplicables.
          </p>
          <h2 className="mt-4 text-xl font-bold">3. Responsabilidad:</h2>
          <ul className="mb-4 list-disc pl-4">
            <li>
              No asumimos responsabilidad alguna por el mal uso que usted haga
              de la Plataforma web <strong>CONTROLCENSO.COM</strong>. Usted
              utiliza la Plataforma web bajo su propia responsabilidad.
            </li>
            <li>
              No garantizamos la disponibilidad, precisión, integridad o
              confiabilidad de la Plataforma web o su contenido.
            </li>
            <li>
              No seremos responsables de ningún daño directo, indirecto,
              incidental, especial, consecuente o punitivo que surja del uso o
              la imposibilidad de utilizar la Plataforma web.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">4. Propiedad Intelectual:</h2>
          <ul className="mb-4 list-disc pl-4">
            <li>
              Todos los derechos de propiedad intelectual relacionados con la
              Plataforma web <strong>CONTROLCENSO.COM</strong> y su contenido
              son propiedad de (ALHASOFT) y de terceros licenciantes.
            </li>
            <li>
              Usted acepta no copiar, modificar, distribuir, transmitir,
              mostrar, realizar, reproducir, publicar, licenciar, crear trabajos
              derivados, transferir o vender ninguna información, software,
              productos o servicios obtenidos de o a través de la Plataforma web
              <strong>CONTROLCENSO.COM</strong>.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">5. Enlaces a Terceros:</h2>
          <ul className="mb-4 list-disc pl-4">
            <li>
              La Plataforma web <strong>CONTROLCENSO.COM</strong> puede contener
              enlaces a sitios web de terceros. Estos enlaces se proporcionan
              únicamente para su conveniencia y no implican ningún respaldo por
              nuestra parte.
            </li>
            <li>
              No tenemos control sobre el contenido de estos sitios web de
              terceros y no asumimos responsabilidad alguna por ellos.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">6. Modificaciones:</h2>
          <ul className="mb-4 list-disc pl-4">
            <li>
              Nos reservamos el derecho de modificar, suspender o discontinuar
              la Plataforma web en cualquier momento, con o sin previo aviso.
            </li>
            <li>
              No seremos responsables ante usted ni ante terceros por ninguna
              modificación, suspensión o discontinuación de la Plataforma web
              <strong>CONTROLCENSO.COM</strong>.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">7. Ley Aplicable:</h2>
          <p>
            Este Aviso Legal se regirá e interpretará de acuerdo a las leyes del
            Estado Plurinacional de Bolivia. Cualquier disputa que surja en
            relación con este Aviso Legal se resolverá en arreglo a la Ley Nº
            708 de Conciliación y Arbitraje.
          </p>
          <h1
            className="mb-4 mt-9 text-center text-3xl font-bold"
            id="avisoCookies"
          >
            POLÍTICA DE USO DE COOKIES
          </h1>
          <h2 className="mt-4 text-xl font-bold">¿Qué son las cookies?</h2>
          <p>
            Las cookies son pequeños archivos de texto que se almacenan en tu
            dispositivo cuando visitas un sitio web. Estos archivos contienen
            información que se utiliza para mejorar tu experiencia de navegación
            y proporcionar funciones específicas al sitio web.
          </p>
          <h2 className="mt-4 text-xl font-bold">
            ¿Cómo utilizamos las cookies?
          </h2>
          <p>En nuestra plataforma, utilizamos cookies con el fin de:</p>
          <ul className="list-disc pl-8">
            <li>
              Mejorar la funcionalidad: Las cookies nos ayudan a recordar tus
              preferencias y ajustes mientras navegas por nuestro sitio. Esto
              incluye cosas como tu idioma preferido y la configuración de
              privacidad.
            </li>
            <li>
              Analizar el uso del sitio: Utilizamos cookies para recopilar
              información sobre cómo los visitantes interactúan con nuestro
              sitio. Esto nos permite entender mejor cómo se utiliza la
              plataforma y realizar mejoras en función de estos datos.
            </li>
            <li>
              Publicidad personalizada: Podemos utilizar cookies para mostrar
              anuncios personalizados que sean relevantes para ti según tus
              intereses y actividades en línea.
            </li>
          </ul>
          <h2 className="mt-4 text-xl font-bold">
            ¿Cómo puedes controlar las cookies?
          </h2>
          <p>
            Puedes controlar y administrar las cookies en tu dispositivo a
            través de la configuración de tu navegador. Sin embargo, debes tener
            en cuenta que deshabilitar ciertas cookies puede afectar la
            funcionalidad de nuestro sitio y limitar tu experiencia de
            navegación.
          </p>
          <p>
            Al continuar utilizando nuestra plataforma, aceptas el uso de
            cookies de acuerdo con esta Política de Cookies. Si no estás de
            acuerdo con el uso de cookies, te recomendamos que ajustes la
            configuración de tu navegador o que no utilices nuestro sitio.
          </p>
          <p>
            Esta Política de Cookies puede actualizarse ocasionalmente para
            reflejar cambios en nuestras prácticas o en la legislación
            aplicable. Te recomendamos que revises esta página periódicamente
            para estar al tanto de cualquier actualización.
          </p>
          <p>
            Si tienes alguna pregunta o inquietud sobre nuestra Política de
            Cookies, no dudes en ponerte en contacto con nosotros a través de
            los canales de soporte proporcionados en nuestro sitio web.
          </p>
          <p>
            Gracias por utilizar nuestra plataforma y por contribuir a la
            iniciativa ciudadana de seguimiento de{' '}
            <strong>control social</strong> al CENSO BOLIVIA 2024.
          </p>
          <h1
            className="mb-4 mt-9 text-center text-3xl font-bold"
            id="avisoresponsabildades"
          >
            DESCARGO DE RESPONSABILIDAD
          </h1>
          <p>
            Por medio de la presente, el/la usuario/a de la plataforma web{' '}
            <strong>CONTROLCENSO.COM</strong>; acepta ceder de manera voluntaria
            los datos necesarios con el único fin de coadyuvar a un{' '}
            <strong>control social</strong> efectivo al CENSO BOLIVIA 2024. Los
            datos recabados serán usados con ese único fin.
          </p>
          <p>
            Yo como usuario de la plataforma web{' '}
            <strong>CONTROLCENSO.COM</strong>; acepto de manera voluntaria
            proporcionar los datos necesarios que ayuden a colaborar en el{' '}
            <strong>control social</strong> efectivo al CENSO BOLIVIA 2024. Los
            datos recabados no podrán, ni serán usados para otro fin que no sea
            el de realizar un <strong>control social</strong> al CENSO BOLIVIA
            2024.
          </p>
          <p>
            Los desarrolladores en calidad de contratados, ni los contratantes,
            tienen los permisos necesarios para acceder a las fotografías de las
            boletas censales subidas voluntariamente.
          </p>
          <p>
            Si tiene alguna pregunta sobre los términos y condiciones,
            comuníquese con nosotros a través de{' '}
            <span className="text-blue-600 underline">alhasoft@gmail.com</span>
          </p>
          <p>
            Gracias por utilizar nuestra plataforma y por contribuir a la
            iniciativa ciudadana de control social al CENSO BOLIVIA 2024.
          </p>
          <div className="mt-3 flex items-center justify-center text-xl font-semibold">
            <Link to={'/subir-acta'} className="text-blue-600 underline">
              Volver al inicio
            </Link>
          </div>
        </div>
        <DownloadButton />
      </div>
      <Footer />
    </>
  )
}

export { TermsAndConditionsPage }
