import { QueryClient, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'

type TRejectUserParams = {
  id: string
}

export const rejectUser = ({ id }: TRejectUserParams): Promise<unknown> => {
  return controlCenso.patch(`/reject-user/${id}`)
}

export const useRejectUser = () => {
  const queryClient = new QueryClient()
  return useMutation({
    onError(error) {
      console.log('🚀 ~ onError ~ error:', error)
      toast.error('No se pudo rechazar al ususario')
    },
    onSuccess: data => {
      toast.success('Se rechazao al usuario')
      queryClient.invalidateQueries({ queryKey: queryKeys.all() })
    },
    mutationFn: rejectUser
  })
}
