/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import { UserDocuments } from '@/types/user'
import controlCenso from '@/utils/axiosConfig'

type TGetUsersParams = {
  id?: string | null
}

export const getUsersDocuments = async ({
  id
}: TGetUsersParams): Promise<UserDocuments[]> => {
  return controlCenso.get(`/documents/${id}`).then(({ data }) => data)
}

type TUseUserParams = {
  id: string | null
}

export const useUserDocuments = ({ id }: TUseUserParams) => {
  return useQuery({
    queryKey: queryKeys.documents(id),
    retry: false,
    queryFn: () => getUsersDocuments({ id })
  })
}
