import { ChangeEvent, useState } from 'react'
import { Button, Input } from '@material-tailwind/react'

import { Controller } from 'react-hook-form'
import Select from 'react-select'

import { useUploadActPage } from './hooks/useUploadActPage'
import { DownloadButton, Footer, UploadIcon } from '@/components/index'
import { useCreateSurvey } from '@/api/surveys'

function UploadActPage() {
  const [currentFile, setCurrentFile] = useState<File | null>()
  const [url, setUrl] = useState<string | null>()
  const {
    form: { control, handleSubmit, reset, watch },
    items,
    handleOnSearch,
    codeError,
    municipalityError
  } = useUploadActPage()

  const { mutateAsync, isPending } = useCreateSurvey()

  const handleChange = (file: ChangeEvent<HTMLInputElement>) => {
    setCurrentFile(file.target.files![0])
    const reader = new FileReader()
    reader.readAsDataURL(file.target.files![0])
    reader.onload = () => {
      setUrl(reader.result as string)
    }
  }

  console.log('-------- CLICK -------', watch())

  const onUpload = handleSubmit(data => {
    console.log('CLICK')
    mutateAsync({
      files: [currentFile!],
      municipalityId: data.municipalityId,
      surveyCode: data.surveyCode
    }).then(() => {
      setCurrentFile(null)
      setUrl(null)
      reset()
    })
  })

  return (
    <div className="relative overflow-auto">
      <div className="flex !h-[calc(100dvh-58px)] w-full flex-col items-center justify-center gap-4 overflow-y-auto bg-gray-300 py-7 md:h-auto md:py-0">
        <div className="h-fit w-full rounded-lg bg-white p-7 shadow-2xl md:max-w-3xl">
          <h1 className="mb-4 text-center text-3xl font-bold">Subir Acta</h1>
          <p className="text-center text-sm text-gray-700">
            Los archivos que serán subidos se mantendrán{' '}
            <strong>completamente anónimos</strong>, sin ninguna posibilidad de
            asociarlos con un usuario específico
          </p>
          <div className="mt-5">
            {!currentFile && (
              <label className="flex h-60 w-full cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dashed border-blue-500 bg-white">
                <UploadIcon
                  stroke={2.5}
                  className="!h-12 !w-12 text-blue-600"
                />
                <p className="mt-3 text-center text-lg font-bold text-black">
                  Suba una nueva acta
                </p>
                <input
                  onChange={handleChange}
                  id="front"
                  type="file"
                  className="hidden"
                  accept=".jpg,.png"
                />
              </label>
            )}
            {currentFile && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 place-content-center gap-4">
                  <div className="flex flex-col items-center justify-center rounded">
                    {url && (
                      <div>
                        <img
                          src={url}
                          alt={`Foto frontal: ${0 + 1}`}
                          className="mt-2 w-full"
                        />
                        <label htmlFor="front" className="w-full">
                          <p className="m-auto flex w-fit cursor-pointer items-center gap-1 rounded-md bg-blue-600 p-2 text-white">
                            <UploadIcon stroke={2.5} className="!h-5 !w-5" />
                            Subir otra foto
                          </p>
                        </label>
                        <input
                          onChange={handleChange}
                          id="front"
                          type="file"
                          className="hidden"
                          accept=".jpg,.png"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center space-y-10">
                    <Controller
                      control={control}
                      name="municipalityId"
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obligatorio'
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <div>
                          <Select
                            options={items}
                            onInputChange={value => handleOnSearch(value)}
                            onChange={item => onChange(item?.value)}
                            placeholder="Seleccione un municipio"
                          />
                          {!!municipalityError && (
                            <p className="text-sm text-red-400">
                              {municipalityError.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="surveyCode"
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obligatorio'
                        },
                        minLength: {
                          value: 3,
                          message: 'Debe tener más de 3 caracteres'
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <div>
                          <Input
                            value={value}
                            onChange={onChange}
                            error={!!codeError}
                            size="lg"
                            variant="outlined"
                            label="Código de la acta"
                            crossOrigin={''}
                            placeholder="Roberto Rojas"
                          />
                          {!!codeError && (
                            <p className="text-sm text-red-400">
                              {codeError.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <Button
                  onClick={onUpload}
                  fullWidth
                  placeholder={''}
                  loading={isPending}
                  variant="gradient"
                >
                  <p className="text-center text-lg">Subir Acta</p>
                </Button>
              </div>
            )}
          </div>
        </div>
        <DownloadButton />
      </div>
      <Footer />
    </div>
  )
}

export { UploadActPage }
