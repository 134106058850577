import { useAddToHomescreenPrompt } from 'pages/LoginPage/hooks'
import React, { useEffect, useState } from 'react'

const DownloadButton = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()
  const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    if (prompt) {
      setShowButton(true)
    }
  }, [prompt])

  if (!showButton) return <></>

  return (
    <button
      className="fixed bottom-16 right-6 flex items-center gap-1 rounded-md bg-[#3A98B9] p-2 text-white outline-none"
      onClick={promptToInstall}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
        />
      </svg>
      Descargar App.
    </button>
  )
}

export { DownloadButton }
