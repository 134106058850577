import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type TSidebarItemProps = {
  icon?: ReactNode
  text: string
  link: string
}
function SidebarItem({ text, icon, link }: TSidebarItemProps) {
  const navigate = useNavigate()

  const handleCLick = () => {
    navigate(link)
  }

  return (
    <button
      onClick={handleCLick}
      type="button"
      className="flex w-full appearance-none gap-3 bg-black/20 px-4 py-3 text-lg font-semibold text-white outline-none transition-all hover:bg-black/15"
    >
      {icon && icon}
      {text}
    </button>
  )
}

export { SidebarItem }
