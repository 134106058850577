import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'

type UpdateUserDocumentsDto = {
  tags: string[]
  files: File[]
}

export const updateUserDocumentsInfo = async (body: UpdateUserDocumentsDto): Promise<unknown> => {
  return controlCenso.patch('/myselfDocuments', body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => data)
}

export const useUpdateUserDocuments = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onError(error) {
      console.log('🚀 ~ onError ~ error:', error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all })
      console.log('🚀 ~ useLogin ~ data:', data)
    },
    mutationFn: updateUserDocumentsInfo
  })
}
