import { useEffect, useState } from 'react'

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Solo se ejecuta al montar/desmontar el componente

  return width
}

export default useScreenWidth
