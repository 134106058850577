import clsx from 'clsx'
import React from 'react'
import { Footer } from '../Footer'

type TContainerProps = {
  children: React.ReactNode
  className?: HTMLElement['className']
}

const Container = ({ children, className }: TContainerProps) => {
  return (
    <>
      <div
        className={clsx(
          'h-[calc(100dvh-58px)] w-full overflow-y-auto bg-gray-300',
          className
        )}
      >
        {children}
      </div>
      <Footer />
    </>
  )
}

export { Container }
