function PageLoader() {
  return (
    <div className="w-screen h-screen items-center justify-center bg-[#645CBB]/[0.50] flex flex-col gap-4">
      <img className="w-[80%] md:w-96" src="/images/alhasoft/logo_alhasoft_primario.png"/>
      <div
  className="inline-block h-16 w-16 animate-spin rounded-full border-[6px] border-solid border-[#3A98B9] border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
  role="status">
  <span
    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>
    </div>
  )
}

export { PageLoader }
