/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import { UserEntity } from '@/types/user'
import controlCenso from '@/utils/axiosConfig'

export const getCurrentUser = async (): Promise<UserEntity> => {
  return controlCenso.get('/myself').then(({ data }) => data)
}

export const useCurrentUser = () => {
  return useQuery({
    queryKey: queryKeys.all,
    retry: false,
    queryFn: getCurrentUser
  })
}
