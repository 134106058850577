/* eslint-disable no-unused-vars */
enum InternalRoleEnum {
  USER = 'user'
}

enum ExternalRoleEnum {
  ENCUESTADO = 'encuestado',
  ENCUESTADOR = 'encuestador'
}

interface IRegisterEntity {
  id: string;
  name: string
  lastName: string;
  identification_number: string;
  internalRole: InternalRoleEnum;
  externalRole: ExternalRoleEnum;
}

export type { IRegisterEntity }

export { InternalRoleEnum, ExternalRoleEnum }
