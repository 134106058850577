import { get, useForm } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useMunicipalities } from '@/api/surveys'

const useUploadActPage = () => {
  const [filter, setFilter] = useState('')
  const form = useForm<{
    municipalityId: string
    surveyCode: string
  }>()

  const { data = [], refetch } = useMunicipalities({
    params: {
      filter
    }
  })

  const items = useMemo(
    () => [
      { label: 'Seleccione un municipio', value: '' },
      ...data.map(item => ({
        label: item.name,
        value: item.id
      }))
    ],
    [data]
  )

  useEffect(() => {
    refetch()
  }, [filter])

  const handleOnSearch = (string: string) => {
    setFilter(string)
  }

  const handleOnSelect = (item: string) => {
    // the item selected
    console.log(item)
  }

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          id: {item.id}
        </span>
        <span style={{ display: 'block', textAlign: 'left' }}>
          name: {item.name}
        </span>
      </>
    )
  }

  const municipalityError = get(form.formState.errors, 'municipalityId')
  const codeError = get(form.formState.errors, 'surveyCode')

  return {
    handleOnSearch,
    handleOnSelect,
    form,
    items,
    formatResult,
    municipalityError,
    codeError
  }
}

export { useUploadActPage }
