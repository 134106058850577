import { Input, Option, Select } from '@material-tailwind/react'
import { Controller, get, UseFormReturn } from 'react-hook-form'
import { ExternalRoleEnum } from 'types'
import { UserEntity } from '@/types/user'

type TRegisterFormProps = {
  form: UseFormReturn<UserEntity>
}

function RegisterForm({ form }: TRegisterFormProps) {
  const {
    control,
    formState: { errors }
  } = form

  const nameError = get(errors, 'name')
  const cedulaError = get(errors, 'cedula')
  const outRoleError = get(errors, 'outRole')

  return (
    <div className="flex flex-col gap-12">
      <div>
        <Controller
          control={control}
          name="name"
          rules={{
            required: 'Este campo es obligatorio',
            pattern: {
              value: /^[a-zA-Z\s]*$/,
              message: 'Ingrese un nombre válido'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              onChange={onChange}
              size="lg"
              error={!!nameError}
              variant="static"
              label="Nombre y Apellido"
              crossOrigin={''}
              placeholder="Roberto Rojas"
            />
          )}
        />
        {!!nameError && (
          <p className="text-sm text-red-400">{nameError.message}</p>
        )}
      </div>
      <div>
        <Controller
          control={control}
          name="cedula"
          rules={{
            required: 'Este campo es obligatorio',
            pattern: {
              value: /^\d{7}$/,
              message: 'Debe tener solo 7 dígitos y números'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              value={value}
              onChange={onChange}
              size="lg"
              error={!!cedulaError}
              variant="static"
              label="Carnet de identidad"
              crossOrigin={''}
              placeholder="9785344"
            />
          )}
        />
        {!!cedulaError && (
          <p className="text-sm text-red-400">{cedulaError.message}</p>
        )}
      </div>
      <div>
        <Controller
          control={control}
          name="outRole"
          rules={{
            required: 'Este campo es obligatorio'
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              onChange={onChange}
              placeholder={''}
              error={!!outRoleError}
              variant="static"
              label="¿Es censista o censado?"
            >
              <Option value={ExternalRoleEnum.ENCUESTADOR}>Censista</Option>
              <Option value={ExternalRoleEnum.ENCUESTADO}>Censado</Option>
            </Select>
          )}
        />
        {!!outRoleError && (
          <p className="text-sm text-red-400">{outRoleError.message}</p>
        )}
      </div>
    </div>
  )
}

export { RegisterForm }
