function Footer() {
  return (
    <div className="broder-t flex items-center justify-center gap-1 border-t-2 bg-[#736BC2]/[0.90] px-3 py-4 text-white">
      <img src="/images/alhasoft/logo_alhasoft_icono.png" className="w-5" />
      <a
        href="https://alhasoft.com/"
        target="_blank"
        className="font-semibold text-black/60 transition-all duration-700 hover:underline"
        rel="noreferrer"
      >
        Alhasoft
      </a>
      <p className="font-semibold">Especialistas en blockchain</p>
    </div>
  )
}

export { Footer }
