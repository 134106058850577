import { QueryClient, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { InRoleEnum } from '@/types/user'

type TupdateUserRoleParams = {
  id: string
  role: InRoleEnum
}

export const updateUserRole = ({
  id,
  role
}: TupdateUserRoleParams): Promise<unknown> => {
  return controlCenso.patch(`/modify-role/${id}`, {
    inRole: role
  })
}

export const useUpdateUserRole = () => {
  const queryClient = new QueryClient()
  return useMutation({
    onError(error) {
      console.log('🚀 ~ onError ~ error:', error)
      toast.error('No se pudo actualizar el ususario')
    },
    onSuccess: data => {
      toast.success('Se actualizo el usuario')
      queryClient.invalidateQueries({ queryKey: queryKeys.all() })
    },
    mutationFn: updateUserRole
  })
}
