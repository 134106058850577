import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { SurveyEntity } from '@/types/surveys'

type CreateSurveyDto = {
  municipalityId: string
  surveyCode: string
  files: File[]
}

export const createSurvey = async (
  body: CreateSurveyDto
): Promise<SurveyEntity> => {
  return controlCenso
    .post('/surveys', body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(({ data }) => data)
}

export const useCreateSurvey = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onError(error) {
      console.log('🚀 ~ onError ~ error:', error)
      toast.error('No se encuentra la opción de subir actas aún')
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all() })
      toast.success('Se subio el acta correctamente')
    },
    mutationFn: createSurvey
  })
}
