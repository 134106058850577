// import { DocumentIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { SidebarItem } from './components'
import { UserIcon } from '../icons'

type TSidebarProps = {
  isOpen: boolean
  handleClose: () => void
}

function Sidebar({ isOpen, handleClose }: TSidebarProps) {
  return (
    <div
      className={clsx(
        'fixed top-0 h-screen w-screen md:sticky md:block md:w-72',
        isOpen ? 'z-[100]' : 'z-0'
      )}
    >
      {isOpen && (
        <div
          className="fixed z-[99] h-screen w-screen bg-black bg-opacity-40"
          onClick={handleClose}
        ></div>
      )}
      <div
        className={clsx(
          'relative z-[9999] h-full w-full max-w-72  bg-gray-800 transition-all duration-500 md:translate-x-0',
          !isOpen ? '-translate-x-72 ' : 'translate-x-0'
        )}
      >
        <div className="w-full px-4">
          <img src="/images/logo.png" className="w-full" />
        </div>
        <SidebarItem
          link="/admin/registros"
          icon={<UserIcon />}
          text="Registros"
        />
        <SidebarItem
          link="/admin/actas-subidas"
          icon={<DocumentIcon className="w-6" />}
          text="Actas subidas"
        />
      </div>
    </div>
  )
}

export { Sidebar }
