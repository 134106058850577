import { useEffect, useState } from 'react'
import { useSurvey, useSurveysCount } from '@/api/surveys'
import { SurveyEntity } from '@/types/surveys'

const useUploadActsTableState = () => {
  const { data } = useSurveysCount()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyEntity | null>(
    null
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const { data: surveyContent, refetch } = useSurvey({
    id: selectedSurvey?.id || null
  })

  console.log('🚀 ~ useUploadActsTableState ~ surveyContent:', surveyContent)

  useEffect(() => {
    setTotalPages(Math.ceil((data?.count || 1) / 30))
  }, [data])

  useEffect(() => {
    refetch()
  }, [selectedSurvey])

  const handleNext = () => {
    setCurrentPage(currentPage => {
      if (currentPage < totalPages) return currentPage + 1
      return currentPage
    })
  }

  const goToPage = (page: number) => setCurrentPage(page)

  const handleBefore = () => {
    setCurrentPage(currentPage => {
      if (currentPage > 1) return currentPage - 1
      return currentPage
    })
  }

  const handleOpenModal = (survey: SurveyEntity) => {
    setIsModalOpen(true)
    setSelectedSurvey(survey)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedSurvey(null)
  }

  return {
    handleBefore,
    currentPage,
    goToPage,
    handleNext,
    handleOpenModal,
    handleCloseModal,
    isModalOpen,
    selectedSurvey,
    setSelectedSurvey,
    total: data?.count || 0
  }
}

export { useUploadActsTableState }
