import { useState } from 'react'

import { Navigate, Outlet } from 'react-router-dom'
import clsx from 'clsx'
import useScreenWidth from 'helper/useScreenWidth'
import { PageLoader, Sidebar } from '@/components/index'
import { useCurrentUser } from '@/api/auth'
import { InRoleEnum } from '@/types/user'

type TAdminLayoutProps = {
  className?: string
}

function AdminLayout({ className }: TAdminLayoutProps) {
  const { data, isError, isLoading } = useCurrentUser()
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const width = useScreenWidth()

  if (isLoading) return <PageLoader />
  if ((isError && !data) || data?.inRole === InRoleEnum.USER) {
    return <Navigate to={'/'} />
  }

  const handleOpen = () => {
    setSidebarIsOpen(state => !state)
  }

  return (
    <div className="relative flex h-screen w-full">
      <button
        className={clsx(
          'fixed left-8 top-8 z-[999] flex aspect-square cursor-pointer items-center justify-center rounded-full bg-black p-2 text-white transition-all duration-300 md:hidden',
          sidebarIsOpen ? 'translate-x-72' : 'translate-x-0',
          width >= 720 ? '!hidden' : 'flex'
        )}
        type="button"
        onClick={handleOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <Sidebar isOpen={sidebarIsOpen} handleClose={handleOpen} />
      <div className="max-h-screen w-full overflow-y-auto">
        <Outlet />
      </div>
    </div>
  )
}

export { AdminLayout }
