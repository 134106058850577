import { createBrowserRouter, Navigate } from 'react-router-dom'
import {
  LoginPage,
  ManagmentActsPage,
  ManagmentUsersPage,
  ManangamentDeleteProfilePage,
  NotFoundPage,
  PoliticsAndPrivacyPage,
  SignUpPage,
  TermsAndConditionsPage,
  UploadActPage
} from '../pages'
import { AdminLayout } from '../components'
import { Auth } from '../utils'
// import { Auth } from '../utils'

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '/registro',
    element: <Auth />,
    children: [
      {
        path: '',
        element: <SignUpPage />
      }
    ]
  },
  {
    path: '/terminos-condiciones',
    element: <TermsAndConditionsPage />
  },
  {
    path: '/politicas-de-privacidad',
    element: <PoliticsAndPrivacyPage />
  },
  {
    path: '/aviso-legal',
    element: <PoliticsAndPrivacyPage />
  },
  {
    path: '/Descargo de responsabilidad',
    element: <PoliticsAndPrivacyPage />
  },
  {
    path: '/subir-acta',
    element: <Auth shouldRedirect />,
    children: [
      {
        path: '',
        element: <UploadActPage />
      }
    ]
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      { path: '', element: <Navigate to={'/admin/registros'} /> },
      {
        path: 'eliminar-cuenta',
        element: <ManangamentDeleteProfilePage />
      },
      {
        path: 'registros',
        element: <ManagmentUsersPage />
      },
      {
        path: 'actas-subidas',
        element: <ManagmentActsPage />
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
])

export { router }
