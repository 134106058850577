/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { MunicipalityEntity } from '@/types/surveys'

type TGetMunicipalityParams = {
  filter?: string
}

export const getMunicipality = async ({
  filter
}: TGetMunicipalityParams): Promise<MunicipalityEntity[]> => {
  return controlCenso
    .get('/municipalities', {
      params: {
        filter: {
          where: { name: { ilike: '%' + filter + '%' } }
        }
      }
    })
    .then(({ data }) => data)
}

type TUseSurveysParams = {
  params: TGetMunicipalityParams
}

export const useMunicipalities = ({ params }: TUseSurveysParams) => {
  return useQuery({
    queryKey: queryKeys.municipalities(params.filter),
    retry: false,
    queryFn: () => getMunicipality(params)
  })
}
