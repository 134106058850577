import { useEffect, useState } from 'react'
import { InRoleEnum, UserEntity, VerificationStateEnum } from '@/types/user'
import { usseVerifyUser } from '@/api/users/verify-user'
import { useRejectUser } from '@/api/users/reject-user'
import { useDeleteUser, useUpdateUserRole, useUsersCount } from '@/api/users'

type TUseUsersTableStateProps = {
  state: VerificationStateEnum | ''
}

const useUsersTableState = ({ state }: TUseUsersTableStateProps) => {
  const { data, refetch } = useUsersCount({ state })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState('')

  useEffect(() => {
    setTotalPages(Math.ceil((data?.count || 1) / 30))
  }, [data])

  useEffect(() => {
    refetch()
  }, [state])

  const { mutateAsync: mutateAsyncAprove, isPending: isMutatingVerify } =
    usseVerifyUser()
  const { mutateAsync: mutateAsyncReject, isPending: isMutatingReject } =
    useRejectUser()
  const { mutateAsync: mutateAsyncDeleteUser, isPending: isMutatingDelete } =
    useDeleteUser()
  const { mutateAsync: mutateAsyncRole, isPending: isMutatingRole } =
    useUpdateUserRole()

  const handleNext = () => {
    setCurrentPage(currentPage => {
      if (currentPage < totalPages) return currentPage + 1
      return currentPage
    })
  }

  const goToPage = (page: number) => setCurrentPage(page)

  const handleBefore = () => {
    setCurrentPage(currentPage => {
      if (currentPage > 1) return currentPage - 1
      return currentPage
    })
  }

  const handleOpenModal = (user: UserEntity) => {
    setIsModalOpen(true)
    setSelectedUser(user)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedUser(null)
  }
  const handleOpenModalDelete = (user: UserEntity) => {
    setIsModalOpenDelete(true)
    setSelectedUser(user)
  }

  const handleCloseModalDelete = () => {
    setIsModalOpenDelete(false)
    setSelectedUser(null)
  }

  const loadImageFromBuffer = (imageData: {
    data: number[]
    type: string
  }): string => {
    if (!imageData) return ''
    try {
      // Convertir los datos en un ArrayBuffer
      const buffer = new Uint8Array(imageData.data).buffer
      // Crear un Blob a partir del buffer
      const blob = new Blob([buffer])
      // Crear la URL de la imagen
      const imageUrl = URL.createObjectURL(blob)
      return imageUrl
    } catch (error) {
      console.error('Error al cargar la imagen:', error)
      return '' // O manejar el error de alguna otra manera, según sea necesario
    }
  }

  const getUserState = (state: VerificationStateEnum) => {
    switch (state) {
      case VerificationStateEnum.APPROVED:
        return {
          text: 'Aprobado',
          color: 'bg-green-200 text-green-700',
          changeState: false
        }
      case VerificationStateEnum.IN_PROGRESS:
        return {
          text: 'En progreso',
          color: 'bg-blue-200 text-blue-700',
          changeState: true
        }
      case VerificationStateEnum.INACTIVE:
        return {
          text: 'Inactivo',
          color: 'bg-gray-300 text-gray-700',
          changeState: true
        }
      case VerificationStateEnum.IN_VERIFICATION:
        return {
          text: 'En verificación',
          color: 'bg-yellow-300 text-yellow-700',
          changeState: true
        }
      case VerificationStateEnum.REJECTED:
        return {
          text: 'Rechazado',
          color: 'bg-red-200 text-red-700',
          changeState: false
        }
    }
  }

  const handelVerify = (userId: string, callback?: () => void) => {
    mutateAsyncAprove({
      id: userId
    }).then(() => {
      handleCloseModal()
      setSelectedUser(null)
      callback?.()
    })
  }
  const handleReject = (userId: string, callback?: () => void) => {
    mutateAsyncReject({
      id: userId
    }).then(() => {
      handleCloseModal()
      setSelectedUser(null)
      callback?.()
    })
  }

  const handleDeleteUser = (userId: string, callback?: () => void) => {
    mutateAsyncDeleteUser({
      id: userId
    }).then(() => {
      handleCloseModalDelete()
      setSelectedUser(null)
      callback?.()
    })
  }

  const handleUpdateRole = (
    userId: string,
    role: InRoleEnum,
    callback?: () => void
  ) => {
    mutateAsyncRole({
      id: userId,
      role
    }).then(() => {
      setSelectedUser(null)
      callback?.()
    })
  }

  return {
    selectedUser,
    setSelectedUser,
    isModalOpen,
    setIsModalOpen,
    handleOpenModal,
    handleCloseModal,
    getUserState,
    loadImageFromBuffer,
    handelVerify,
    handleReject,
    isMutatingVerify,
    isMutatingReject,
    currentPage,
    handleNext,
    handleBefore,
    totalPages,
    goToPage,
    handleDeleteUser,
    isModalOpenDelete,
    setIsModalOpenDelete,
    isMutatingDelete,
    handleOpenModalDelete,
    handleCloseModalDelete,
    handleUpdateRole,
    isMutatingRole,
    filter,
    setFilter,
    total: data?.count || 0
  }
}

export { useUsersTableState }
