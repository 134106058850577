/* eslint-disable multiline-ternary */
import {
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/solid'
import {
  // Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  IconButton,
  Input,
  Tooltip,
  Typography
} from '@material-tailwind/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useUsersTableState } from './hooks'
import { TabsState } from './components'
import { UserIcon } from '../icons'
import { useUserDocuments, useUsers } from '@/api/users'
import { InRoleEnum, OutRoleEnum, VerificationStateEnum } from '@/types/user'

const TABLE_HEAD = [
  '#',
  'Nombre',
  'Cedula de identidad',
  'Tipo de usuario',
  'Rol',
  'Estado',
  ''
]

export function UsersTable() {
  const [stateFilter, setStateFilter] = useState<VerificationStateEnum | ''>('')
  // const { data: myselfData } = useCurrentUser()
  const [updateUserState, setUpdateUserState] = useState<InRoleEnum>()
  const [updateRoleModal, setUpdateRoleModal] = useState(false)
  const {
    isModalOpen,
    handleCloseModal,
    handleOpenModal,
    getUserState,
    selectedUser,
    loadImageFromBuffer,
    handelVerify,
    handleReject,
    isMutatingReject,
    isMutatingVerify,
    currentPage,
    handleBefore,
    handleNext,
    totalPages,
    goToPage,
    handleDeleteUser,
    isModalOpenDelete,
    isMutatingDelete,
    handleCloseModalDelete,
    handleOpenModalDelete,
    handleUpdateRole,
    isMutatingRole,
    setSelectedUser,
    filter,
    setFilter,
    total
  } = useUsersTableState({ state: stateFilter })

  const { data = [], refetch } = useUsers({
    params: {
      skip: (currentPage - 1) * 30,
      limit: 30,
      verificationState: stateFilter as any,
      filter
    }
  })

  const { data: userDocument, isLoading: loadingDocuments } = useUserDocuments({
    id: selectedUser?.id || null
  })

  useEffect(() => {
    refetch()
  }, [stateFilter, currentPage, filter])
  useEffect(() => {
    goToPage(1)
  }, [filter])

  const handelChageState = (state: VerificationStateEnum | '') => {
    setStateFilter(state)
    goToPage(1)
  }

  return (
    <>
      <Card
        placeholder={''}
        className="h-full w-full"
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
        <CardHeader
          placeholder={''}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          floated={false}
          shadow={false}
          className="rounded-none"
        >
          <div>
            <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
              <div>
                <Typography placeholder={''} variant="h5" color="blue-gray">
                  Usuario Registrados
                </Typography>
                <Typography
                  placeholder={''}
                  color="gray"
                  className="mt-1 font-normal"
                >
                  Usuarios Que faltan ser verificados
                </Typography>
                <Chip value={`${total} Usuarios`} className="my-2 !w-fit" />
              </div>
              <div className="flex w-full shrink-0 gap-2 md:w-max">
                <div className="w-full md:w-72">
                  <Input
                    placeholder=""
                    crossOrigin={() => {}}
                    onChange={event => setFilter(event.target.value)}
                    label="Buscar por cédula"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </div>
            <TabsState
              currentState={stateFilter}
              handleChageState={handelChageState}
            />
          </div>
        </CardHeader>
        <CardBody
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder={''}
          className="overflow-auto px-0"
        >
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map(head => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      placeholder={''}
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((user, index) => {
                const isLast = index === data.length - 1
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50'

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          placeholder={''}
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {index + 1}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        {/* <Avatar
                            placeholder={''}
                            src={img}
                            alt={name}
                            size="md"
                            className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                          /> */}
                        <Typography
                          placeholder={''}
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {user.name}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        placeholder={''}
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {user.cedula}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        placeholder={''}
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {user.outRole === OutRoleEnum.RESPONDENT
                          ? 'Encuestado'
                          : 'Encuestador'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        placeholder={''}
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {user.inRole === InRoleEnum.ANALYST
                          ? 'Analista'
                          : user.inRole === InRoleEnum.SUPER
                            ? 'Super Admin'
                            : user.inRole === InRoleEnum.VERIFIER
                              ? 'Verificador'
                              : 'Usuario'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex w-full justify-center">
                        <p
                          className={clsx(
                            'm-auto w-fit rounded-full px-4 py-2 font-bold',
                            getUserState(user.verificationState).color
                          )}
                        >
                          {getUserState(user.verificationState).text}
                        </p>
                      </div>
                    </td>
                    <td className={classes}>
                      {getUserState(user.verificationState).changeState && (
                        <Tooltip content="Verificar">
                          <IconButton
                            placeholder={''}
                            variant="text"
                            onClick={() => handleOpenModal(user)}
                          >
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip content="Actualizar Rol">
                        <IconButton
                          placeholder={''}
                          variant="text"
                          onClick={() => {
                            setUpdateUserState(user.inRole)
                            setSelectedUser(user)
                            setUpdateRoleModal(true)
                          }}
                        >
                          <UserIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Eliminar">
                        <IconButton
                          placeholder={''}
                          variant="text"
                          onClick={() => handleOpenModalDelete(user)}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder={''}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <Button
            onClick={handleBefore}
            disabled={currentPage === 1}
            placeholder={''}
            variant="outlined"
            size="sm"
          >
            Anterior
          </Button>
          <div className="flex items-center gap-2">
            <IconButton placeholder={''} variant="outlined" size="sm">
              {currentPage}
            </IconButton>
          </div>
          <Button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            placeholder={''}
            variant="outlined"
            size="sm"
          >
            Siguiente
          </Button>
        </CardFooter>
      </Card>
      <Dialog placeholder={''} open={isModalOpen} handler={() => {}} size="md">
        <DialogBody placeholder={''}>
          <h2 className="mb-3 text-center text-2xl font-bold text-black/80">
            Verificar usuario
          </h2>

          <div className="grid w-full grid-cols-3 gap-4 gap-y-8 ">
            {loadingDocuments ? (
              <>
                <div className="aspect-square w-full animate-pulse rounded-md bg-gray-300"></div>
                <div className="aspect-square w-full animate-pulse rounded-md bg-gray-300"></div>
                <div className="aspect-square w-full animate-pulse rounded-md bg-gray-300"></div>
              </>
            ) : (
              <>
                <div>
                  <p className="text-center font-bold">
                    Foto de Carnet trasera
                  </p>
                  {userDocument?.find(item => item.tag === 'back')?.buffer ? (
                    <img
                      loading="lazy"
                      src={loadImageFromBuffer(
                        userDocument?.find(item => item.tag === 'back')
                          ?.buffer as any
                      )}
                      className="bg-gray-200"
                    />
                  ) : (
                    <p className="text-center font-medium text-red-400">
                      SIN FOTO
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-center font-bold">
                    Foto de Carnet frontal
                  </p>
                  {userDocument?.find(item => item.tag === 'front')?.buffer ? (
                    <img
                      loading="lazy"
                      src={loadImageFromBuffer(
                        userDocument?.find(item => item.tag === 'front')
                          ?.buffer as any
                      )}
                      className="bg-gray-200"
                    />
                  ) : (
                    <p className="text-center font-medium text-red-400">
                      SIN FOTO
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-center font-bold">Foto selfie</p>
                  {userDocument?.find(item => item.tag === 'selfie')?.buffer ? (
                    <img
                      loading="lazy"
                      src={loadImageFromBuffer(
                        userDocument?.find(item => item.tag === 'selfie')
                          ?.buffer as any
                      )}
                      className="bg-gray-200"
                    />
                  ) : (
                    <p className="text-center font-medium text-red-400">
                      SIN FOTO
                    </p>
                  )}
                </div>
              </>
            )}
            <div className="">
              <p>Nombre completo</p>
              <p className="text-lg font-bold text-black">
                {selectedUser?.name}
              </p>
            </div>
            <div className="">
              <p>Carnet</p>
              <p className="text-lg font-bold text-black">
                {selectedUser?.cedula}
              </p>
            </div>
            <div className="">
              <p>Tipo</p>
              <p className="text-lg font-bold text-black">
                {selectedUser?.outRole === OutRoleEnum.RESPONDENT
                  ? 'Encuestado'
                  : 'Encuestador'}
              </p>
            </div>
          </div>
        </DialogBody>
        <DialogFooter
          placeholder={''}
          className="flex w-full justify-between gap-3"
        >
          <Button
            placeholder={''}
            variant="text"
            color="indigo"
            onClick={handleCloseModal}
            disabled={isMutatingVerify || isMutatingReject}
            fullWidth
            className="w-full flex-1"
          >
            <span>Cancelar</span>
          </Button>
          <Button
            placeholder={''}
            variant="gradient"
            color="red"
            disabled={isMutatingVerify}
            loading={isMutatingReject}
            onClick={() => handleReject(selectedUser!.id, refetch)}
            fullWidth
            className="w-full flex-1"
          >
            <span>RECHAZAR</span>
          </Button>
          <Button
            variant="gradient"
            loading={isMutatingVerify}
            disabled={isMutatingReject}
            placeholder={''}
            color="green"
            onClick={() => handelVerify(selectedUser!.id, refetch)}
            className="w-full flex-1"
          >
            <span>APROBAR</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        placeholder={''}
        open={isModalOpenDelete}
        handler={() => {}}
        size="xs"
      >
        <DialogBody placeholder={''}>
          <h2 className="mb-3 text-center text-2xl font-bold text-black/80">
            Eliminar Usuario
          </h2>
          <p className="text-center">
            ¿Esta seguró de relizar esta acción?, una vez elimine al usuario se
            perdera toda su información
          </p>
        </DialogBody>
        <DialogFooter
          placeholder={''}
          className="flex w-full justify-between gap-3"
        >
          <Button
            placeholder={''}
            variant="text"
            color="indigo"
            onClick={handleCloseModalDelete}
            disabled={isMutatingDelete}
            fullWidth
            className="w-full flex-1"
          >
            <span>Cancelar</span>
          </Button>
          <Button
            variant="gradient"
            loading={isMutatingDelete}
            placeholder={''}
            color="red"
            onClick={() => handleDeleteUser(selectedUser!.id, refetch)}
            className="w-full flex-1"
          >
            <span>Eliminar</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        placeholder={''}
        open={updateRoleModal}
        handler={() => {}}
        size="md"
      >
        <DialogBody placeholder={''}>
          <h2 className="mb-3 text-center text-2xl font-bold text-black/80">
            Actualizar usuario
          </h2>
          <div className="space-y-5 text-center">
            <div
              className={clsx(
                'w-full cursor-pointer rounded-md border-2 p-4',
                updateUserState === InRoleEnum.USER && 'border-blue-600'
              )}
              onClick={() => setUpdateUserState(InRoleEnum.USER)}
            >
              Usuario
            </div>
            <div
              className={clsx(
                'w-full cursor-pointer rounded-md border-2 p-4',
                updateUserState === InRoleEnum.ANALYST && 'border-blue-600'
              )}
              onClick={() => setUpdateUserState(InRoleEnum.ANALYST)}
            >
              Analista
            </div>
            <div
              className={clsx(
                'w-full cursor-pointer rounded-md border-2 p-4',
                updateUserState === InRoleEnum.VERIFIER && 'border-blue-600'
              )}
              onClick={() => setUpdateUserState(InRoleEnum.VERIFIER)}
            >
              Verificador
            </div>
            <div
              className={clsx(
                'w-full cursor-pointer rounded-md border-2 p-4',
                updateUserState === InRoleEnum.SUPER && 'border-blue-600'
              )}
              onClick={() => setUpdateUserState(InRoleEnum.SUPER)}
            >
              Super Usuario
            </div>
          </div>
        </DialogBody>
        <DialogFooter
          placeholder={''}
          className="flex w-full justify-between gap-3"
        >
          <Button
            placeholder={''}
            variant="text"
            color="indigo"
            onClick={() => setUpdateRoleModal(false)}
            disabled={isMutatingRole}
            fullWidth
            className="w-full flex-1"
          >
            <span>Cancelar</span>
          </Button>
          <Button
            variant="gradient"
            loading={isMutatingRole}
            placeholder={''}
            color="blue"
            onClick={() =>
              handleUpdateRole(selectedUser!.id, updateUserState!, () => {
                refetch()
                setUpdateRoleModal(false)
              })
            }
            className="w-full flex-1"
          >
            <span>Actualizar</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}
