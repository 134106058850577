/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import { UserDocuments } from '@/types/user'
import controlCenso from '@/utils/axiosConfig'

type TGetSurveyParams = {
  id: string | null
}

export const getSurvey = async ({
  id
}: TGetSurveyParams): Promise<UserDocuments[]> => {
  return controlCenso
    .get(`/surveys/${id}`, {
      data: {
        id
      }
    })
    .then(({ data }) => data)
}

type TUseSurveyParams = {
  id: string | null
}

export const useSurvey = ({ id }: TUseSurveyParams) => {
  return useQuery({
    queryKey: queryKeys.documents(id),
    retry: false,
    queryFn: () => {
      if (!id) return
      getSurvey({ id })
    }
  })
}
