/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import { UserEntity, VerificationStateEnum } from '@/types/user'
import controlCenso from '@/utils/axiosConfig'

type TGetUsersParams = {
  limit?: number
  skip: number
  verificationState?: VerificationStateEnum
  filter?: string
}

export const getUsers = async ({
  limit = 30,
  skip,
  verificationState,
  filter
}: TGetUsersParams): Promise<UserEntity[]> => {
  const stateFilter = verificationState
    ? {
        verificationState
      }
    : {}
  const cedulaFilter = filter
    ? {
        cedula: { ilike: '%' + filter + '%' }
      }
    : {}
  return controlCenso
    .get('/users', {
      params: {
        limit,
        skip,
        filter: {
          where: {
            ...stateFilter,
            ...cedulaFilter
          },
          order: ['verificationState DESC'],
          include: [
            {
              relation: 'submissionProgress'
            }
          ]
        }
      }
    })
    .then(({ data }) => data)
}

type TUseUserParams = {
  params: TGetUsersParams
}

export const useUsers = ({ params }: TUseUserParams) => {
  return useQuery({
    queryKey: queryKeys.all(params.skip),
    retry: false,
    queryFn: () => getUsers(params)
  })
}
