import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { TFiles, UserEntity } from '@/types/user'

type TUseRegisterStateProps = {
  data?: UserEntity
}

function useRegisterState({ data }: TUseRegisterStateProps) {
  const form = useForm<UserEntity>({
    mode: 'all'
  })

  const filesState = useState<TFiles[]>([
    {
      tag: 'front',
      file: null
    },
    {
      tag: 'back',
      file: null
    },
    {
      tag: 'selfie',
      file: null
    }
  ])

  const { reset } = form

  useEffect(() => {
    reset({ ...data })
  }, [data])

  return { form, filesState }
}

export { useRegisterState }
