import { Typography } from '@material-tailwind/react'
import { useDepartment } from '@/api/surveys'

type TDepartmentProps = {
  municipalityId: string
}

const Department = ({ municipalityId }: TDepartmentProps) => {
  const { data, isLoading } = useDepartment({
    params: {
      id: municipalityId
    }
  })
  if (isLoading) {
    return <div className="h-2 w-8 animate-pulse rounded bg-gray-500"></div>
  }
  return (
    <Typography
      placeholder={''}
      variant="small"
      color="blue-gray"
      className="font-normal"
    >
      {data?.name}
    </Typography>
  )
}

export { Department }
