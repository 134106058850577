import React, { useEffect, useMemo } from 'react'
import { Button, Step, Stepper, Typography } from '@material-tailwind/react'

import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useRegisterState } from './hooks'
import { RegisterForm } from '../RegisterForm'
import { FilesForm } from '../FilesForm'
import { PictureIcon, UserIcon } from '@/components/index'
import { UserEntity } from '@/types/user'
import {
  useCurrentUser,
  useUpdatePersonalInfo,
  useUpdateUserDocuments
} from '@/api/auth'

type TStepperWithContentProps = {
  currentPosition: number
}

export function StepperWithContent({
  currentPosition
}: TStepperWithContentProps) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [isLastStep, setIsLastStep] = React.useState(false)
  const [isFirstStep, setIsFirstStep] = React.useState(false)
  const { data } = useCurrentUser()

  const navigate = useNavigate()

  const userPersonalInfoMutation = useUpdatePersonalInfo()
  const userDocumentsMutation = useUpdateUserDocuments()
  const { form, filesState } = useRegisterState({ data })

  const [files] = filesState

  const filesAreCompleted = useMemo(() => {
    return files.every(item => !!item.file)
  }, [filesState])

  useEffect(() => {
    setActiveStep(currentPosition)
  }, [currentPosition])

  const onSubmitPersonalInfo = (data: UserEntity) => {
    userPersonalInfoMutation
      .mutateAsync({
        cedula: data.cedula,
        name: data.name,
        outRole: data.outRole
      })
      .then(() => {
        setActiveStep(cur => cur + 1)
      })
  }

  const onSubmitDocuments = () => {
    userDocumentsMutation
      .mutateAsync({
        tags: files.map(item => item.tag) as string[],
        files: files.map(item => item.file) as File[]
      })
      .then(() => {
        navigate('/subir-acta')
      })
  }

  const handleNext = (data: UserEntity) =>
    !isLastStep ? onSubmitPersonalInfo(data) : onSubmitDocuments()
  const handlePrev = () => !isFirstStep && setActiveStep(cur => cur - 1)

  return (
    <div className="flex h-full min-h-[calc(100dvh-58px)] w-full items-center justify-center bg-gray-300">
      <div className="w-full max-w-xl rounded-lg bg-white p-7 shadow-2xl">
        <Typography placeholder={''} variant="h1" className="mb-6 text-center">
          Formulario de Registro
        </Typography>
        <Stepper
          placeholder={''}
          activeStep={activeStep}
          isLastStep={value => setIsLastStep(value)}
          isFirstStep={value => setIsFirstStep(value)}
        >
          <Step placeholder={''} onClick={handlePrev}>
            <UserIcon className="h-5 w-5" />
            <div className="absolute -bottom-[2.5rem] w-max text-center">
              <Typography
                placeholder={''}
                variant="h6"
                color={activeStep === 0 ? 'blue-gray' : 'gray'}
              >
                Datos
              </Typography>
            </div>
          </Step>
          <Step placeholder={''} onClick={form.handleSubmit(handleNext)}>
            <PictureIcon className="h-5 w-5" />
            <div className="absolute -bottom-[2.5rem] w-max text-center">
              <Typography
                placeholder={''}
                variant="h6"
                color={activeStep === 1 ? 'blue-gray' : 'gray'}
              >
                Fotos
              </Typography>
            </div>
          </Step>
        </Stepper>
        <p className="mt-14 text-sm font-light text-gray-600">
          <span className="text-base font-bold text-yellow-700">Atención:</span>{' '}
          Los datos solicitados se utilizarán exclusivamente con el{' '}
          <span className="font-semibold">
            propósito de verificar que el usuario es una persona
          </span>{' '}
          y no un programa automatizado.
        </p>
        <div className={clsx('mt-8 w-full', activeStep !== 0 && 'hidden')}>
          <RegisterForm form={form} />
        </div>
        <div className={clsx('mt-8 w-full', activeStep !== 1 && 'hidden')}>
          <FilesForm filesState={filesState} />
        </div>
        <div className="mt-10 flex justify-between">
          <Button
            placeholder={''}
            onClick={handlePrev}
            disabled={isFirstStep || userDocumentsMutation.isPending}
          >
            Anterior
          </Button>
          <Button
            loading={
              userPersonalInfoMutation.isPending ||
              userDocumentsMutation.isPending
            }
            disabled={isLastStep && !filesAreCompleted}
            placeholder={''}
            onClick={form.handleSubmit(handleNext)}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  )
}
