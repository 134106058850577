/* eslint-disable no-unused-vars */
enum VerificationStateEnum {
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_VERIFICATION = 'IN_VERIFICATION',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

enum InRoleEnum {
  USER = 'USER',
  ANALYST = 'ANALYST',
  VERIFIER = 'VERIFIER',
  SUPER = 'SUPER'
}
enum OutRoleEnum {
  RESPONDENT = 'RESPONDENT',
  SURVEYOR = 'SURVEYOR'
}

enum RegisterStateEnum {
  INITIAL = 'INITIAL',
  PERSONAL = 'PERSONAL',
  DOCUMENTS = 'DOCUMENTS'
}

interface SubmissionProgress {
  id: string
  stepSubmission: RegisterStateEnum
  userId: string
}
interface UserEntity {
  id: string
  name: string
  realm: any | null
  username: string
  cedula: string
  inRole: InRoleEnum
  outRole: OutRoleEnum
  email: string
  verificationState: VerificationStateEnum
  submissionProgress: SubmissionProgress
}

type TFiles = {
  tag: 'front' | 'back' | 'selfie'
  file: File | null
}

type UserDocuments = {
  tag: 'front' | 'back' | 'selfie'
  buffer: {
    type: string
    data: Buffer
  }
}

export type { UserEntity, TFiles, UserDocuments }

export { VerificationStateEnum, InRoleEnum, OutRoleEnum, RegisterStateEnum }
