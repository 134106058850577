import { UplaodedActsTable } from '@/components/UploadedActsTable'

function ManagmentActsPage() {
  return (
    <div className="p-4">
      <UplaodedActsTable />
    </div>
  )
}

export { ManagmentActsPage }
