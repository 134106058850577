import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { StepperWithContent } from './components'

import { RegisterStateEnum } from '@/types/user'
import { Footer } from '@/components/index'
import { useCurrentUser } from '@/api/auth'

function SignUpPage() {
  const [stepPosition, setStepPosition] = useState(0)
  const { data } = useCurrentUser()
  useEffect(() => {
    const url = window.location.href
    const cookie = url.split('?')[1]

    if (cookie) {
      document.cookie = ''
      const registerState = decodeURIComponent(cookie.split('&')[0])?.split(
        '='
      )[1]
      if (registerState === RegisterStateEnum.DOCUMENTS) setStepPosition(2)
      if (registerState === RegisterStateEnum.PERSONAL) setStepPosition(1)
      if (registerState === RegisterStateEnum.INITIAL) setStepPosition(0)
    }
    if (
      data?.submissionProgress.stepSubmission === RegisterStateEnum.DOCUMENTS
    ) {
      setStepPosition(2)
    }
  }, [data?.submissionProgress.stepSubmission])
  if (stepPosition === 2) return <Navigate to={'/subir-acta'} />

  return (
    <>
      <StepperWithContent currentPosition={stepPosition} />
      <Footer />
    </>
  )
}

export { SignUpPage }
