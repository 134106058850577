import React, { ChangeEvent, useState } from 'react'
import { UploadIcon } from '@/components/icons'
import { TFiles } from '@/types/user'

type TFilesFormProps = {
  filesState: [TFiles[], React.Dispatch<React.SetStateAction<TFiles[]>>]
}

function FilesForm({ filesState } :TFilesFormProps) {
  const [selectedFiles, setSelectedFiles] = filesState
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, index: number, tag: 'front' | 'back' | 'selfie') => {
    const file = event.target.files?.[0]
    if (file) {
      const newSelectedFiles = [...selectedFiles]
      newSelectedFiles[index].file = file
      newSelectedFiles[index].tag = tag
      setSelectedFiles(newSelectedFiles)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const newImageUrls = [...imageUrls]
        newImageUrls[index] = reader.result as string
        setImageUrls(newImageUrls)
      }
    }
  }

  return (
    <div className="w-full flex flex-col gap-5">
      {!selectedFiles[0].file && (
        <>
          <label htmlFor='front' className="w-full rounded-xl bg-gray-200 border-dashed h-28 flex flex-col justify-center items-center cursor-pointer">
          <UploadIcon stroke={2.5} className='text-gray-500'/>
          <p className="text-gray-500 text-center font-bold">Foto frontal de su carnet</p>
          <input onChange={(event) => handleFileChange(event, 0, 'front')} id='front' type="file" className='hidden' accept=".jpg,.png" />
        </label>
        </>
      )}
      {selectedFiles[0].file && (
            <div className='items-center flex justify-center flex-col'>
              <p className="text-gray-700">{`Archivo seleccionado: ${selectedFiles[0].file?.name}`}</p>
              {imageUrls[0] && (
                <img
                  src={imageUrls[0]}
                  alt={`Foto frontal: ${0 + 1}`}
                  className="mt-2 max-w-[70%] max-h-[200px]"
                />
              )}
              <label htmlFor='front' className="">
          <p className='!w-fit flex gap-1 items-center cursor-pointer bg-black/80 p-3  rounded-md'>
            <UploadIcon stroke={2.5} className='text-gray-500 '/>
            <p className="text-gray-500 text-center font-bold">Subir otra foto</p>
          </p>
          <input onChange={(event) => handleFileChange(event, 0, 'front')} id='front' type="file" className='hidden' accept=".jpg,.png" />
      </label>
            </div>
      )}
      {!selectedFiles[1].file && (
        <label className="w-full rounded-xl bg-gray-200 border-dashed h-28 flex flex-col justify-center items-center cursor-pointer">
          <UploadIcon stroke={2.5} className='text-gray-500' />
          <p className="text-gray-500 text-center font-bold">Foto trasera de su carnet</p>
          <input id='back' onChange={(event) => handleFileChange(event, 1, 'back')} type="file" className='hidden' accept=".jpg,.png" />
        </label>
      )}
      {selectedFiles[1].file && (
            <div className='flex items-center justify-center flex-col'>
              <p className="text-gray-700">{`Archivo seleccionado: ${selectedFiles[1].file?.name}`}</p>
              {imageUrls[1] && (
                <img
                  src={imageUrls[1]}
                  alt={`Foto trasera: ${1 + 1}`}
                  className="mt-2 max-w-[70%] max-h-[200px]"
                />
              )}
                            <label className="w-fit">
                            <p className='!w-fit flex gap-1 items-center cursor-pointer bg-black/80 p-3  rounded-md'>
            <UploadIcon stroke={2.5} className='text-gray-500 '/>
            <p className="text-gray-500 text-center font-bold">Subir otra foto</p>
          </p>
          <input onChange={(event) => handleFileChange(event, 1, 'back')} id='back' type="file" className='hidden' accept=".jpg,.png" />
      </label>
            </div>
      )}
      {!selectedFiles[2].file && (
        <label className="w-full rounded-xl bg-gray-200 border-dashed h-28 flex flex-col justify-center items-center cursor-pointer">
          <UploadIcon stroke={2.5} className='text-gray-500' />
          <p className="text-gray-500 text-center font-bold">Selfie</p>
          <input id='selfie' onChange={(event) => handleFileChange(event, 2, 'selfie')} type="file" className='hidden' accept=".jpg,.png" />
        </label>
      )}
      {selectedFiles[2].file && (
            <div className='flex items-center justify-center flex-col'>
              <p className="text-gray-700">{`Archivo seleccionado: ${selectedFiles[2].file?.name}`}</p>
              {imageUrls[2] && (
                <img
                  src={imageUrls[2]}
                  alt={`Foto selfie: ${2 + 1}`}
                  className="mt-2 max-w-[70%] max-h-[200px]"
                />
              )}
                    <label className="">
                    <p className='!w-fit flex gap-1 items-center cursor-pointer bg-black/80 p-3  rounded-md'>
            <UploadIcon stroke={2.5} className='text-gray-500 '/>
            <p className="text-gray-500 text-center font-bold">Subir otra foto</p>
          </p>
          <input onChange={(event) => handleFileChange(event, 2, 'selfie')} id='selfi' type="file" className='hidden' accept=".jpg,.png" />
      </label>
            </div>
      )}

    </div>
  )
}

export { FilesForm }
