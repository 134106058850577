import { Tab, Tabs, TabsHeader } from '@material-tailwind/react'
import { VerificationStateEnum } from '@/types/user'

type TTabsStateProps = {
  currentState: VerificationStateEnum | ''
  handleChageState?: (state: VerificationStateEnum | '') => void
}

function TabsState({ currentState, handleChageState }: TTabsStateProps) {
  const data = [
    {
      label: 'Todos',
      value: ''
    },
    {
      label: 'En progreso',
      value: VerificationStateEnum.IN_PROGRESS
    },
    {
      label: 'En verificación',
      value: VerificationStateEnum.IN_VERIFICATION
    },
    {
      label: 'Aprobados',
      value: VerificationStateEnum.APPROVED
    },
    {
      label: 'Rechazados',
      value: VerificationStateEnum.REJECTED
    }
  ]

  return (
    <Tabs value={currentState}>
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => handleChageState?.(value as any)}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
    </Tabs>
  )
}

export { TabsState }
