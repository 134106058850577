/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { VerificationStateEnum } from '@/types/user'

type TGetUsersCountParams = {
  state: VerificationStateEnum | ''
}

export const getUsersCount = async ({
  state
}: TGetUsersCountParams): Promise<{ count: number }> => {
  const filter = state
    ? {
        where: {
          verificationState: state
        }
      }
    : {}
  return controlCenso
    .get('/users/count', {
      params: { ...filter }
    })
    .then(({ data }) => data)
}

export const useUsersCount = ({ state }: TGetUsersCountParams) => {
  return useQuery({
    queryKey: queryKeys.counter,
    retry: false,
    queryFn: () => getUsersCount({ state })
  })
}
