import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { OutRoleEnum } from '@/types/user'

type UpdateUserPersonalInfoDto = {
  name: string
  cedula: string
  outRole: OutRoleEnum
}

export const updateUserPersonalInfo = async (
  body: UpdateUserPersonalInfoDto
): Promise<unknown> => {
  return controlCenso.patch('/myselfData', body).then(({ data }) => data)
}

export const useUpdatePersonalInfo = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all })
    },
    mutationFn: updateUserPersonalInfo
  })
}
