import { UsersTable } from '@/components/UsersTable'

function ManagmentUsersPage() {
  return (
    <div className="p-4">
      <UsersTable />
    </div>
  )
}

export { ManagmentUsersPage }
