/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { DepartmentEntity } from '@/types/surveys'

type TGetMunicipalityParams = {
  id?: string
}

export const getDepartment = async ({
  id
}: TGetMunicipalityParams): Promise<DepartmentEntity> => {
  return controlCenso
    .get(`/municipalities/${id}/department`)
    .then(({ data }) => data)
}

type TUseSurveysParams = {
  params: TGetMunicipalityParams
}

export const useDepartment = ({ params }: TUseSurveysParams) => {
  return useQuery({
    queryKey: queryKeys.departments(params.id),
    retry: false,
    queryFn: () => getDepartment(params)
  })
}
