/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'
import { SurveyEntity } from '@/types/surveys'

type TGetSurveysParams = {
  limit?: number
  skip: number
}

export const getSurveys = async ({
  limit = 30,
  skip
}: TGetSurveysParams): Promise<SurveyEntity[]> => {
  return controlCenso
    .get('/surveys', {
      params: {
        limit,
        skip,
        filter: {
          include: [
            {
              relation: 'municipality'
            }
          ]
        }
      }
    })
    .then(({ data }) => data)
}

type TUseSurveysParams = {
  params: TGetSurveysParams
}

export const useSurveys = ({ params }: TUseSurveysParams) => {
  return useQuery({
    queryKey: queryKeys.all(params.skip),
    retry: false,
    queryFn: () => getSurveys(params)
  })
}
