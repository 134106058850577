import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter
} from '@material-tailwind/react'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { DownloadButton, Footer, PageLoader } from '@/components/index'
import { useCurrentUser } from '@/api/auth'

function LoginPage() {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)

  const { isError, isLoading } = useCurrentUser()

  if (isLoading) {
    return <PageLoader />
  }

  if (!isError) {
    return <Navigate to={'/registro'} />
  }

  return (
    <>
      <div className="relative flex h-[calc(100dvh-58px)] w-full items-center justify-center overflow-y-auto bg-gray-300">
        <div className="flex h-full w-full flex-col items-center justify-center rounded-lg bg-white p-6 shadow-2xl md:h-auto md:min-h-0 md:max-w-[420px]">
          <img
            className="w-full object-cover"
            src="/images/control_censo/logo_control_censo.png"
          />
          <div className="mt-3 flex w-full flex-col items-center justify-center gap-4">
            <Button
              placeholder={''}
              variant="filled"
              color="white"
              className="flex w-full items-center justify-center gap-3 border-2 border-gray-300 shadow-2xl"
              onClick={handleOpen}
            >
              <img className="w-7" src="/images/google_icon.png" />
              Iniciar sesión con Google
            </Button>
          </div>
        </div>
        <DownloadButton />
      </div>
      <Footer />
      <Dialog placeholder={''} open={isOpen} handler={handleOpen} size="md">
        <DialogBody placeholder={''}>
          <h2 className="mb-3 text-center text-2xl font-bold text-black/80">
            Terminos de uso
          </h2>
          <p>
            Bienvenido a nuestra plataforma web{' '}
            <strong>CONTROL CENSO.COM</strong> integrada con tecnología de
            cadena de bloques (Blockchain) dedicada al{' '}
            <strong>control social</strong> del proceso del CENSO BOLIVIA 2024.
            Antes de continuar navegando en nuestro sitio, es importante que
            conozcas nuestros términos y condiciones, que describen:
          </p>
          <ul className="list-disc pl-8">
            <li>
              <a
                href="/terminos-condiciones#avisoCookies"
                className="text-blue-500 underline"
                target="_blank"
              >
                Política de uso de cookies
              </a>
            </li>
            <li>
              <a
                href="/politicas-de-privacidad"
                className="text-blue-500 underline"
                target="_blank"
              >
                Política de privacidad
              </a>
            </li>
            <li>
              <a
                href="/terminos-condiciones#avisolegal"
                className="text-blue-500 underline"
                target="_blank"
              >
                Aviso legal
              </a>
            </li>
            <li>
              <a
                href="/terminos-condiciones#avisoresponsabildades"
                className="text-blue-500 underline"
                target="_blank"
              >
                Descargo de responsabilidades
              </a>
            </li>
          </ul>{' '}
          <p>
            Al utilizar la presente Plataforma web, usted acepta todas las
            siguientes cláusulas:
          </p>
        </DialogBody>
        <DialogFooter
          placeholder={''}
          className="flex w-full justify-between gap-3"
        >
          <Button
            placeholder={''}
            variant="text"
            color="red"
            onClick={() => setIsOpen(false)}
            fullWidth
            className="w-full flex-1"
          >
            <span>Cancelar</span>
          </Button>
          <Button
            variant="gradient"
            placeholder={''}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_BACKEND_API}/auth/thirdparty/google`
              )
            }
            className="w-full flex-1"
          >
            <span>Acepto</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export { LoginPage }
