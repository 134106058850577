import { Button } from '@material-tailwind/react'
import { Container } from '@/components/index'

const NotFoundPage = () => {
  return (
    <Container className="flex items-center justify-center">
      <div className="flex max-w-md flex-col justify-center gap-11 rounded-lg bg-white p-5 shadow-2xl">
        <h1 className="text-center text-3xl font-bold">
          No encontramos la página que estabas buscando
        </h1>
        <img src="/images/page not found.png" className="m-auto max-w-60" />
        <div className="flex justify-center">
          <Button>Volver al inicio</Button>
        </div>
      </div>
    </Container>
  )
}

export { NotFoundPage }
