/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import { DownloadButton, Footer } from '@/components/index'

function PoliticsAndPrivacyPage() {
  return (
    <>
      <div className="relative flex h-[calc(100dvh-58px)] w-full items-center justify-center overflow-y-auto ">
        <div className="m-auto max-w-7xl px-3 py-5 md:px-0">
          <div className="mx-auto max-w-4xl px-4 py-8">
            <Typography
              placeholder={''}
              variant="h1"
              className="mb-6 text-center"
            >
              Politicas de Privacidad
            </Typography>
            <p className="mb-4">
              En la presente política describimos cómo se recopila, utiliza y
              comparte la información personal de los usuarios ("usted" o
              "usuario") en la plataforma web{' '}
              <span className="font-bold">CONTROLCENSO.COM</span> integrada con
              tecnología de cadena de bloques (Blockchain) y contratos
              inteligentes para el{' '}
              <span className="font-bold">control social</span> del CENSO
              BOLIVIA 2024 a través de la participación ciudadana.
            </p>

            <p className="mb-4">
              La plataforma web CONTROLCENSO.COM utiliza la tecnología emergente
              de cadena de bloques para obtener una transparencia plena por las
              características de una red descentralizada, y para que cualquier
              persona pueda corroborar la integridad, disponibilidad, no repudio
              y autenticidad de los registros (auditoría informática) respetando
              el principio de trazabilidad.
            </p>
            <p className="mb-4">
              Téngase presente que, dichos datos personales, no serán revelados
              a terceros, fuera de los términos de la presente política, y bajo
              ninguna circunstancia será visible (principio de disponibilidad) a
              terceros.
            </p>

            <h2 className="mb-2 text-xl font-bold">
              1. Información que recopilamos:
            </h2>
            <p className="mb-4">
              <strong>a. Información que usted proporciona:</strong> Podemos
              recopilar información personal que usted nos proporciona
              voluntariamente, como su nombre, dirección de correo electrónico, y cualquier otra información que decida
              compartir con nosotros al utilizar la Plataforma web.
            </p>

            <p>
            Lo recomendable es que el ciudadano, al momento de subir la fotografía del acta censal, no lo haga en su totalidad,
            sino únicamente la segunda página del acta censal puesto que dicho capítulo contiene información relevante que, a
            través de la fiscalización/control social, sea la Comisión de Organización Territorial del Estado y Autonomías de
            la Cámara de Diputados y/o los Comités Civicos Departamentales quiénes cotejen y contrasten; cuando se considere
            necesario o cuando haya alguna irregularidad sustentada; con las actas registradas en la base de datos del Instituto
            Nacional de Estadística (INE), preservando así el principio de integridad y confidencialidad. Por lo que no es necesario
            subir todas las páginas del acta censal.
            </p>
            <p className="mb-4">¿Como hemos obtenido tus datos?</p>
            <p>
              Los datos que tratamos en <strong>CONTROLCENSO.COM</strong>{' '}
              proceden de:
            </p>
            <ul className="mb-4 list-disc pl-4">
              <li>Formulario de contacto.</li>
              <li>Formulario de registro.</li>
            </ul>
            <p className="mb-4">
              <strong>b. Información recopilada automáticamente:</strong> Cuando
              usted accede y utiliza la Plataforma web, podemos recopilar cierta
              información automáticamente, su dirección IP, tipo de navegador,
              páginas web visitadas, tiempo de visita y otras estadísticas de
              uso.
            </p>
            <p className="mb-4">
              ¿Cuáles son tus derechos cuando nos otorgas tus datos personales?
              Cualquier persona tiene derecho a obtener una confirmación expresa
              si la plataforma web <strong>CONTROLCENSO.COM</strong> está
              realizando el tratamiento de sus datos personales, pudiendo
              ejercer, en cualquier momento todos los derechos que le asiste la
              normativa vigente.
            </p>
            <h2 className="mb-2 text-xl font-bold">
              2. Uso de la información:
            </h2>
            <p className="mb-4">
              Al conectarse con la plataforma web{' '}
              <strong>CONTROLCENSO.COM</strong> por ejemplo para subir algun
              archivo, mandar un correo al titular, registrarse o realizar
              alguna actividad dentro de la misma, está facilitando información
              de carácter personal como pueden ser tu dirección IP, dirección
              física, dirección de correo electrónico, número de teléfono y otra
              información, al facilitar esta información el usuario da su
              consentimiento para que su información sea recopilada, utilizada,
              gestionada y almacenada por la plataforma web
              <strong>CONTROLCENSO.COM</strong> En la plataforma web{' '}
              <strong>CONTROLCENSO.COM</strong> existen diferentes sistemas de
              captura de información personal, que nos facilitan las personas
              interesadas con el único fin de realizar un control social
              ciudadano al proceso del CENSO BOLIVIA 2024.Dicha recopilación es
              utilizada exclusivamente con la finalidad de corroborar que el
              usuario suscrito es un ser humano.
            </p>
            <ul className="mb-4 list-disc pl-4">
              <li>
                Formulario de registro. La finalidad es mejorar la experiencia
                de usuario.
              </li>
              <li>
                Formulario de contacto. La finalidad es mantener la comunicación
                objeto del propio asunto indicado en el formulario de contacto.
              </li>
              <li>
                Utilizamos la información que recopilamos para proporcionar,
                mantener y mejorar la Plataforma web, así como para desarrollar
                nuevos servicios y funcionalidades.
              </li>
              <li>
                Podemos utilizar su información para comunicarnos con usted,
                responder a sus solicitudes y proporcionarle información
                relevante sobre la Plataforma web y sus servicios.
              </li>
            </ul>

            <h2 className="mb-2 text-xl font-bold">
              3. Compartir información:
            </h2>
            <p className="mb-4">
              a. Podemos compartir su información personal con terceros
              proveedores de servicios que trabajen en nuestro nombre para
              mantener la Plataforma web, sujetos a una cláusula de
              confidencialidad.
            </p>
            <ul className="mb-4 list-disc pl-4">
              <li>Compilación</li>
              <li>Registro</li>
              <li>Difusión</li>
              <li>Fines estadísticos</li>
              <li>Métricas internas</li>
              <li>Compaarativa y contraste de datos</li>
            </ul>
            <p className="mb-4">
              No compartiremos su información personal con terceros fuera de los
              fines descritos en esta Política de Privacidad sin obtener su
              consentimiento previo y expreso, excepto cuando lo exija la ley o
              cuando sea necesario para proteger nuestros derechos o la
              seguridad de los usuarios.
            </p>

            <h2 className="mb-2 text-xl font-bold">
              4. Seguridad de la información:
            </h2>
            <p className="mb-4">
              Tomamos medidas razonables para proteger la información personal
              de los usuarios contra accesos no autorizados, uso indebido o
              divulgación. Sin embargo, no podemos garantizar la seguridad
              absoluta de la información transmitida a través de Internet.
            </p>

            <h2 className="mb-2 text-xl font-bold">
              5. Enlaces a sitios web de terceros:
            </h2>
            <p className="mb-4">
              La Plataforma web puede contener enlaces a sitios web de terceros.
              Esta Política de Privacidad no se aplica a dichos sitios web, y no
              somos responsables de las prácticas de privacidad o el contenido
              de dichos sitios. Le recomendamos que revise las políticas de
              privacidad de los sitios web de terceros antes de proporcionar
              cualquier información personal.
            </p>

            <h2 className="mb-2 text-xl font-bold">
              6. Legitimación para el tratamiento de tus datos; Aceptación y
              consentimiento:
            </h2>
            <ul className="mb-4 list-disc pl-4">
              <li>
                La base legal para el tratamiento de sus datos es el
                consentimiento expreso, previo e informado.
              </li>
              <li>
                Para realizar actividades en la plataforma web{' '}
                <strong>CONTROLCENSO.COM</strong>
                se requiere el consentimiento de esta política de privacidad.
              </li>
              <li>
                El usuario declara haber sido informado de las condiciones sobre
                protección de datos de carácter personal, aceptando y
                consintiendo el tratamiento de los mismos por parte de la
                plataforma web
                <strong>CONTROLCENSO.COM</strong> en la forma y para las
                finalidades indicadas en esta política de privacidad.{' '}
              </li>
            </ul>

            <h2 className="mb-2 text-xl font-bold">
              7. Cambios en esta Política de Privacidad:
            </h2>
            <p className="mb-4">
              Nos reservamos el derecho de actualizar o modificar esta Política
              de Privacidad en cualquier momento. Cualquier cambio entrará en
              vigor desde su publicación en la Plataforma. Le recomendamos que
              revise periódicamente esta Política para estar al tanto de
              cualquier cambio. El uso continuado de la Plataforma web después
              de la publicación de cambios constituirá su aceptación tácita de
              dichos cambios.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <Link to={'/subir-acta'} className="text-blue-600 underline">
              Volver al inicio
            </Link>
          </div>
        </div>
        <DownloadButton />
      </div>
      <Footer />
    </>
  )
}

export { PoliticsAndPrivacyPage }
