/* eslint-disable multiline-ternary */
/* eslint-disable quote-props */
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './query-keys'
import controlCenso from '@/utils/axiosConfig'

export const getSurveysCount = async (): Promise<{ count: number }> => {
  return controlCenso.get('/surveys/count').then(({ data }) => data)
}

export const useSurveysCount = () => {
  return useQuery({
    queryKey: queryKeys.counter,
    retry: false,
    queryFn: () => getSurveysCount()
  })
}
