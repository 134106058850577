import axios from 'axios'

const baseURL = process.env.REACT_APP_BACKEND_API

const controlCenso = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

controlCenso.interceptors.request.use(async config => {
  return config
})

export default controlCenso
